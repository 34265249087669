// This is the homepage.

import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import loadable from '@loadable/component'
import { Link } from "gatsby-plugin-modal-routing"
import Instagram from '../../static/assets/Insta.svg'

import ItemThumbnail from '../components/ItemThumbnail/ItemThumbnail'
import Hands from '../components/Landing/HandImage'
import Biography from '../components/Landing/Bio'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../../static/assets/Logo.svg"

const Masonry = loadable(() => import('react-masonry-component'))


const Landing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10vh;
  width: 100%;
`
const ImageFrame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

`
const Title = styled(Logo) `
  width: 100%;
`

const InstaLink = styled(Link) `
  display: flex;
  font-size: 0.9em;
  align-items: center;
  margin: 0 6vw 0 auto;
  text-decoration: none;
  color: black;
`

class Index extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const items = data.allMarkdownRemark.edges

    //Sort the array so that it's in date order.
    function compare(a, b) {

      var dateA = (a.node.frontmatter.id);
      var dateB = (b.node.frontmatter.id);

      if ( dateA < dateB ) return 1;
      if ( dateA > dateB ) return -1;
      return 0;
    }


    const childElements = items.sort(compare).map(({ node }) => {
      const { title, image, price, sold, gallery, dimensions, description, id, quantity } = node.frontmatter;
      const body = node.html;
      return ( 
        <ItemThumbnail
          key={node.fields.slug}
          id={id}
          quantity={quantity}
          link={node.fields.slug}
          heading={title}
          image={image.childImageSharp.fluid}
          basketImage={image.childImageSharp.fluid.src}
          price={price}
          sold={sold}
          gallery={gallery}
          width={dimensions.width}
          height={dimensions.height}
          weight={(dimensions.height*dimensions.width)*0.42}
          description={description}
          items={this.props.items}
          body={body}
        />
      )
    })

    return (
      <Layout title={siteTitle}>
        <SEO title="Gallery" />
        <Landing>
          <ImageFrame>
            <Hands />
          </ImageFrame>
          <ImageFrame>
            <Title />
            <InstaLink to="https://www.instagram.com/nickygreenshields">Follow Nicky on Instagram<Instagram style={{marginLeft: '1vh'}}/></InstaLink>
          </ImageFrame>
          <Biography />
        </Landing>
        <Masonry enableResizableChildren={true} style={{margin: '0 auto'}} options={{
           horizontalOrder: true, fitWidth: true, gutter: 10, 
       }}>
          {childElements}
        </Masonry>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {frontmatter: {collection: {ne: "footer"}}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            title
            id
            quantity
            date
            sold
            gallery
            price
            description
            dimensions {
              width
              height
            }
            image {
              childImageSharp {
                fluid(maxWidth: 480) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby-plugin-modal-routing";

import Canvas from "./Canvas"
import Magnify from "../../../static/assets/Magnify.svg"
import Basket from "./basket"


const ItemThumbnailStyled = styled.div`
    position: relative;
    padding-top: 4vh;
    width: 280px;
    margin: 0 auto;

    :focus {
        outline: none;
        border: none;
    }

    @media (max-width: 768px) and (orientation: portrait) {
    width: 300px;
    }
    
    @media (min-width: 2048px) {
        width: 520px;
        padding: 2vh;
    }
`

const HoverLink = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    opacity: ${props => (props.open ? "1" : "0")};
    top: ${props => (props.open ? "0" : "-2em")};
    transition: all 0.3s ease;

    p {
        font-family: ${({ theme }) => theme.font.serif}, Georgia, serif;
        font-weight: 200;
        font-size: 1em;
        line-height: 1.5;
        color: black;
    }
`

const ZoomLink = styled(Link)`
    text-align: right;
    padding-top: 8px; 
    
    :active >* {
      transform: scale(0.9);  
    }
`
 
const PicButton = styled.button`
    position: relative;
    width: 100%;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
`

const Heading = styled.h3`
    margin-top: 0;
    font-family: ${({ theme }) => theme.font.serif};
    font-size: 1em;
    font-weight: 500;
    color: black;
`

const Dot = styled.div`
    opacity: ${props => (props.dot ? "0" : "1")};
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: red;
    bottom: 2%;
    right: 4%;
    z-index: 1;
`

const ItemThumbnail = (props) => {
    const [detailsReveal, setdetailsReveal] = useState(false)
    const thumbnail = React.useRef()
    const basket = false;

    const handleFocus = (e) => {
        setTimeout(() => {
            thumbnail.current.focus();
            thumbnail.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              }); 
        }, 100);
        
        setdetailsReveal(detailsReveal => true);
    }

    const handleBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setdetailsReveal(detailsReveal => false);
          }
    }

    
    return (
        <ItemThumbnailStyled className="grid-sizer" ref={thumbnail} tabIndex="-1"
        onClick={handleFocus} onBlur={handleBlur} >
            <PicButton aria-label={props.heading}>
                <Canvas>
                    <Img style={{borderRadius: '2px'}} fluid={props.image} />

                    {props.sold ? (
                        <Dot />
                      ) : (
                        <Dot dot />
                      )} 
                </Canvas>    
            </PicButton>
            {detailsReveal ? (
                <HoverLink open>
                    <ZoomLink to={props.link} asModal><Magnify /></ZoomLink>
                    <Heading>{props.heading}</Heading>
                    <p>{props.width.toFixed(0)}cm x {props.height.toFixed(0)}cm</p>
                    <p>{props.description}</p>
                    <p dangerouslySetInnerHTML={{ __html: props.body }} />
                    <p>£{props.price.toFixed(2)}</p>
                    
                    <Basket basket={basket} 
                        link={props.link} 
                        id={props.id}
                        quantity={props.quantity}
                        price={props.price}
                        sold={props.sold}
                        name={props.heading}
                        description={props.description}
                        image={props.basketImage}
                        width={props.width}
                        weight={props.weight}
                        height={props.height} 
                    />
                </HoverLink>
                
              ) : (
                <HoverLink />
              )} 
            
            
        </ItemThumbnailStyled>
    )
}

export default ItemThumbnail;
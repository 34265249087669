import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"


const Hands = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "Apron" }, extension: { eq: "jpg" }) {
        childImageSharp {
          fluid(maxWidth: 925, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
      <Img fluid={data.file.childImageSharp.fluid} alt="apron" style={{width: '80%', margin: '0 auto'}}/>
  )
}

export default Hands
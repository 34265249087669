import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useState } from "react"

const Container = styled.div `
    display: flex;
    flex-direction: column;
    margin: 4vh 6vw;
`

const Expander = styled.div `
    opacity: ${props => (props.less ? "-webkit-mask-image: unset; mask-image: unset" : "-webkit-mask-image: linear-gradient(to top, transparent 30%, black 100%); mask-image: linear-gradient(to top, transparent 30%, black 100%);")};
    height: ${props => (props.less ? "fit-content" : "7.5em")};
`

const ReadMore = styled.button `
    font-family: ${({ theme }) => theme.font.sans};
    align-self: flex-end;
    background-color: unset;
    color: #aaa;
    font-size: 1em;
    cursor: pointer;

    :focus {
        outline: none;
        border: none;
    }
`

const Biography = () => {
    const [reveal, setReveal] = useState(false)

    const data = useStaticQuery(graphql`
    {
      file(name: {eq: "home"}) {
        childMarkdownRemark {
          frontmatter {
            bio
          }
        }
      }
    }
  `)


  
  return (
      <Container>
        {reveal ? (
                <>  
                    <Expander less>
                        <p>{data.file.childMarkdownRemark.frontmatter.bio}</p>
                    </Expander>
                    <ReadMore onClick={() =>{setReveal(reveal => !reveal)}}>Read Less</ReadMore>
                  
                </>
            ):
            (
                <>
                    <Expander>
                        <p>{data.file.childMarkdownRemark.frontmatter.bio}</p>
                    </Expander>
                    <ReadMore onClick={() =>{setReveal(reveal => !reveal)}}>Read More</ReadMore>
                </>
            )
        }
      </Container>
  )
}

export default Biography